<template>
  <div class="flex-lg-row-fluid">
    <div
      v-if="isLoading"
      style="
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="text-center px-5 pb-7">
        <img
          alt="Logo"
          class="max-h-25px"
          src="/media/loading_icon.gif"
          style="width: 35px"
        />
      </div>
    </div>
    <div v-else-if="jobPostingList.length > 0">
      <div class="row align-items-center">
        <div class="col">
          <h3>{{ $t("modals.interview.jobPostings") }}</h3>
        </div>
        <div class="col-auto text-end">
          <div class="d-flex justify-content-between">
            <!-- Tüm İş İlanları -->
            <div class="ms-2">
              <a
                class="btn btn-sm"
                :class="status === 'ALL' ? 'btn-primary' : 'btn-secondary'"
                @click="filterByStatus('ALL')"
              >
                {{ $t("modals.interview.allJobPostings") }}
              </a>
            </div>

            <!-- Aktif İlanlar -->
            <div class="ms-2">
              <a
                class="btn btn-sm"
                :class="
                  status === JobPostingStatus.ACTIVE
                    ? 'btn-primary'
                    : 'btn-secondary'
                "
                @click="filterByStatus(JobPostingStatus.ACTIVE)"
              >
                {{ $t("modals.interview.activeJobPostings") }}
              </a>
            </div>

            <!-- Pasif İlanlar -->
            <div class="ms-2">
              <a
                class="btn btn-sm"
                :class="
                  status === JobPostingStatus.PASSIVE
                    ? 'btn-primary'
                    : 'btn-secondary'
                "
                @click="filterByStatus(JobPostingStatus.PASSIVE)"
              >
                {{ $t("modals.interview.inactiveJobPostings") }}
              </a>
            </div>
            <div class="ms-2">
              <a
                class="btn btn-sm"
                :class="
                  status === JobPostingStatus.PENDING
                    ? 'btn-primary'
                    : 'btn-secondary'
                "
                @click="filterByStatus(JobPostingStatus.PENDING)"
              >
                {{ $t("modals.interview.pendingJobPostings") }}
              </a>
            </div>
            <div class="ms-2">
              <a
                class="btn btn-sm"
                :class="
                  status === JobPostingStatus.COMPLETED
                    ? 'btn-primary'
                    : 'btn-secondary'
                "
                @click="filterByStatus(JobPostingStatus.COMPLETED)"
              >
                {{ $t("modals.interview.completedJobPostings") }}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="separator my-3"></div>

      <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-5 g-xl-9">
        <!--begin::Col-->

        <div
          class="col-md-4"
          v-for="(jobPosting, index) in filteredJobPostings"
          :key="index"
        >
          <!--begin::Card-->
          <div class="card card-flush h-md-100 shadow-sm hover-effect">
            <span
              v-if="jobPosting.jobPosting.status"
              :class="JobPostingStatusBadge(jobPosting.jobPosting.status)"
            >
              {{
                jobPosting.jobPosting.status === JobPostingStatus.ACTIVE
                  ? $t("common.active")
                  : jobPosting.jobPosting.status === JobPostingStatus.PENDING
                  ? $t("common.waiting")
                  : jobPosting.jobPosting.status === JobPostingStatus.COMPLETED
                  ? $t("common.completed")
                  : $t("common.inactive")
              }}
            </span>
            <!--begin::Card header-->
            <div
              class="
                card-header
                d-flex
                justify-content-between
                align-items-center
                mt-3
              "
            >
              <!-- Başlık -->
              <div class="card-title col-md-9 text-wrap">
                <router-link
                  :to="
                    '/interviewer/jobposting-detail/' +
                    jobPosting.jobPosting.jobPostingId
                  "
                >
                  <h2 class="mb-0 text-hover-primary text-wrap text-break">
                    {{ jobPosting.jobPosting.title }}
                  </h2>
                </router-link>
              </div>
              <!-- Üç Nokta Menüsü -->
              <div
                v-if="
                  jobPosting.jobPosting.status !== JobPostingStatus.COMPLETED &&
                  (jobPosting.jobPosting.status !== JobPostingStatus.PENDING ||
                    (!jobPosting.jobPosting.hasCvAnalysis &&
                      !jobPosting.jobPosting.hasCompletedInterviewSession))
                "
              >
                <button
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bi bi-three-dots fs-3"></i>
                </button>
                <JobPostingDropdown
                  :jobPostingId="jobPosting.jobPosting.jobPostingId"
                />
              </div>
            </div>

            <!-- Kullanıcı Sayısı -->

            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-1">
              <!--begin::Permissions-->

              <div class="fw-bold text-gray-600 mb-3">
                {{ formatLocalizedDate(jobPosting.jobPosting.startDate) }} -
                {{ formatLocalizedDate(jobPosting.jobPosting.endDate) }}
              </div>

              <div class="fw-bold text-gray-600 mb-3">
                <a
                  data-bs-toggle="modal"
                  :data-bs-target="
                    '#descriptionModal' + jobPosting.jobPosting.jobPostingId
                  "
                  class="
                    text-primary text-decoration-underline
                    fw-bold
                    cursor-pointer
                  "
                >
                  {{ $t("modals.interview.viewDetail") }}
                </a>
                <!-- <div
                    v-html="jobPosting.jobPosting.description"
                    class="description-text"
                  ></div> -->
              </div>

              <!--begin::Modal-->
              <div
                class="modal fade"
                :id="'descriptionModal' + jobPosting.jobPosting.jobPostingId"
                tabindex="-1"
                aria-labelledby="descriptionModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="descriptionModalLabel">
                        {{ $t("modals.interview.jobPostingDesc") }}
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body mh-500px overflow-auto">
                      <div v-html="jobPosting.jobPosting.description"></div>

                      <div class="separator my-4"></div>

                      <div
                        v-for="(qualification, i) in jobPosting.jobPosting
                          .jobPostingQualifications"
                        :key="i"
                        class="d-flex align-items-center py-2"
                      >
                        <span class="bullet bg-primary me-3"></span>
                        {{ qualification.text }}
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        {{ $t("common.button.close") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Modal-->

              <router-link
                :to="
                  '/interviewer/jobposting-detail/' +
                  jobPosting.jobPosting.jobPostingId
                "
              >
                <div class="fw-bold text-gray-600 mb-3">
                  {{ $t("modals.interview.candidateCount") }}:
                  {{ jobPosting.jobPosting.candidateCount }}
                </div>

                <div class="d-flex flex-column text-gray-600 pb-5">
                  <div
                    v-for="(qualification, i) in visibleRequirements(
                      jobPosting.jobPosting.jobPostingQualifications
                    )"
                    :key="i"
                    class="d-flex align-items-center py-2"
                  >
                    <span class="bullet bg-primary me-3"></span>
                    {{ qualification.text }}
                  </div>

                  <div
                    v-if="
                      jobPosting.jobPosting.jobPostingQualifications &&
                      jobPosting.jobPosting.jobPostingQualifications.length > 3
                    "
                    class="d-flex align-items-center py-2"
                  >
                    <span class="bullet bg-primary me-3"></span>
                    <em
                      >{{
                        hiddenRequirementCount(
                          jobPosting.jobPosting.jobPostingQualifications
                        )
                      }}
                      {{ $t("modals.interview.morePieces") }}</em
                    >
                  </div>
                </div>
              </router-link>
              <!--end::Permissions-->
              <div
                class="d-flex flex-column flex-md-row justify-content-between"
              >
                <a
                  v-if="
                    jobPosting.jobPosting.status != JobPostingStatus.COMPLETED
                  "
                  class="
                    btn btn-sm btn-primary
                    mb-2 mb-md-0
                    me-md-2
                    flex-grow-2
                    w-100
                  "
                >
                  <router-link
                    :to="
                      '/interviewer/candidate-list/' +
                      jobPosting.jobPosting.jobPostingId
                    "
                    style="
                      color: #ffffff;
                      display: block;
                      width: 100%;
                      height: 100%;
                      text-decoration: none;
                      text-align: center;
                      line-height: normal;
                    "
                  >
                    {{ $t("modals.interview.inviteCandidate") }}
                  </router-link>
                </a>

                <a
                  v-else
                  class="
                    btn btn-sm btn-secondary
                    mb-2 mb-md-0
                    me-md-2
                    flex-grow-2
                    w-100
                  "
                  style="
                    pointer-events: none;
                    background-color: #e0e0e0;
                    color: #6c757d;
                  "
                >
                  {{ $t("modals.interview.inviteCandidate") }}
                </a>

                <router-link
                  :to="
                    '/interviewer/jobposting-detail/' +
                    jobPosting.jobPosting.jobPostingId
                  "
                  class="flex-grow-1"
                >
                  <a
                    class="btn btn-sm btn-secondary btn-active-secondary w-100"
                  >
                    {{ $t("common.button.review") }}
                  </a>
                </router-link>
              </div>

              <div
                class="separator my-4"
                v-if="
                  jobPosting.jobPosting.interviews &&
                  jobPosting.jobPosting.interviews.length > 0
                "
              ></div>

              <div
                class="d-flex flex-wrap gap-2 mb-3"
                v-if="
                  jobPosting.jobPosting.interviews &&
                  jobPosting.jobPosting.interviews.length > 0
                "
              >
                <!-- Görünen mülakat öğeleri -->
                <div
                  v-for="(item, index) in visibleItems(
                    jobPosting.jobPosting.interviews
                  )"
                  :key="index"
                  class="
                    border border-dashed border-gray-300
                    rounded
                    p-4
                    text-center
                    interview-item
                  "
                                    @click="goInterviewDetailPage(item.id)"
                  style="flex: 0 0 auto; min-width: 150px"
                >
                    <span class="fs-6 fw-semibold text-muted lh-1"
                      >{{ item.title }}
                    </span>
                </div>

                <!-- +X butonu -->
                <div
                  v-if="
                    hiddenItemCount(jobPosting.jobPosting.interviews) > 0 &&
                    !showAll
                  "
                  class="
                    border border-dashed border-gray-300
                    rounded
                    p-4
                    text-center
                    interview-item
                  "
                  style="flex: 0 0 auto; cursor: pointer; min-width: 55px"
                  @click="showAllInterviews"
                >
                  <span class="fs-6 fw-semibold text-muted lh-1"
                    >+{{
                      hiddenItemCount(jobPosting.jobPosting.interviews)
                    }}</span
                  >
                </div>

                <!-- Gizli mülakatlar gösterildiğinde -->
                <div
                  v-if="showAll"
                  v-for="(item, index) in hiddenItems(
                    jobPosting.jobPosting.interviews
                  )"
                  :key="index"
                  class="
                    border border-dashed border-gray-300
                    rounded
                    p-4
                    text-center
                    interview-item
                  "
                  @click="goInterviewDetailPage(item.id)"
                  style="flex: 0 0 auto; min-width: 150px"
                >
                    <span class="fs-6 fw-semibold text-muted lh-1">{{
                      item.title
                    }}</span>
                </div>
              </div>
            </div>

            <!--end::Card body-->
          </div>
          <!--end::Card-->
        </div>

        <!--end::Col-->

        <!--begin::Add new card-->
        <div class="col-md-4">
          <!--begin::Card-->
          <div class="card h-md-100 shadow-sm hover-effect">
            <!--begin::Card body-->
            <div class="card-body d-flex flex-center">
              <router-link to="/interviewer/new-jobposting">
                <!--begin::Button-->
                <button
                  type="button"
                  class="btn btn-clear d-flex flex-column flex-center"
                >
                  <!--begin::Illustration-->
                  <img
                    src="/media/illustrations/sketchy-1/4.png"
                    alt=""
                    class="mw-100 mh-150px mb-7"
                  />
                  <!--end::Illustration-->

                  <!--begin::Label-->
                  <div class="fw-bold fs-3 text-gray-600 text-hover-primary">
                    {{ $t("modals.interview.createJobPosting") }}
                  </div>
                  <!--end::Label-->
                </button>
                <!--begin::Button-->
              </router-link>
            </div>
            <!--begin::Card body-->
          </div>
          <!--begin::Card-->
        </div>
        <!--begin::Add new card-->
      </div>

      <!--begin::Pagination-->
      <div
        class="d-flex flex-stack flex-wrap pt-10"
        v-if="jobPostingList.length != 0"
      >
        <!--begin::Controls-->
        <div class="d-flex flex-wrap my-1">
          <!--begin::Select wrapper-->
          <div class="m-0">
            <!--begin::Select-->
            <select
              name="status"
              data-control="select2"
              data-hide-search="true"
              class="
                form-select form-select-white form-select-sm
                fw-bolder
                w-125px
              "
              v-model="pageSize"
              @change="getJobPostingList(1)"
            >
              <option value="5">5</option>
              <option value="10" selected>10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
            <span>{{ $t("common.table.pagination.desc2") }}</span>
            <!-- Sayfa başı öğe sayısı -->

            <!--end::Select-->
          </div>
          <!--end::Select wrapper-->
        </div>
        <!--end::Controls-->

        <div class="fs-6 fw-bold text-gray-700">
          {{ totalItems }} {{ $t("common.table.pagination.desc") }}
          <!-- sonuç arasından -->
          {{ (currentPage - 1) * pageSize + 1 }} -
          {{ Math.min(currentPage * pageSize, totalItems) }}
          {{ $t("common.table.pagination.desc1") }}
          <!-- sonuç gösteriliyor. -->
        </div>

        <!--begin::Pages-->
        <ul class="pagination">
          <li
            class="page-item previous"
            @click="getJobPostingList(currentPage - 1)"
            :class="{ disabled: currentPage === 1 }"
          >
            <a href="#" class="page-link"><i class="previous"></i></a>
          </li>

          <li
            v-for="page in pageCount"
            :key="page"
            class="page-item"
            :class="{ active: currentPage === page }"
          >
            <a href="#" class="page-link" @click="getJobPostingList(page)">{{
              page
            }}</a>
          </li>

          <li
            class="page-item next"
            @click="getJobPostingList(currentPage + 1)"
            :class="{ disabled: currentPage === pageCount }"
          >
            <a href="#" class="page-link"><i class="next"></i></a>
          </li>
        </ul>
        <!--end::Pages-->
      </div>
      <!--end::Pagination-->
    </div>
    <div v-else class="card-px text-center">
      <p class="text-gray-700 fs-6 fw-bold">
        <span> {{ $t("modals.interview.notFoundJobPosting") }}</span>
      </p>

      <div class="d-flex justify-content-center align-items-center h-100">
        <!--begin::Card-->
        <div class="card h-md-100 shadow-sm hover-effect text-center">
          <!--begin::Card body-->
          <div class="card-body d-flex flex-column flex-center">
            <router-link to="/interviewer/new-jobposting">
              <!--begin::Button-->
              <button
                type="button"
                class="btn btn-clear d-flex flex-column flex-center"
              >
                <!--begin::Illustration-->
                <img
                  src="/media/illustrations/sketchy-1/4.png"
                  alt=""
                  class="mw-100 mh-150px mb-7"
                />
                <!--end::Illustration-->

                <!--begin::Label-->
                <div class="fw-bold fs-3 text-gray-600 text-hover-primary">
                  {{ $t("modals.interview.createJobPosting") }}
                </div>
                <!--end::Label-->
              </button>
              <!--end::Button-->
            </router-link>
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Card-->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { JobPostingListModel } from "@/domain/interviewer/job-posting-list/model/JobPostingListModel";
import { VueEditor } from "vue3-editor";
import JobPostingDropdown from "@/presentation/components/dropdowns/JobPostingDropdown.vue";
import { JobPostingInterviewModel } from "@/domain/interviewer/model/JobPostingInterviewModel";
import { JobPostingStatus } from "@/domain/constants/JobPostingStatus";
import { JobPostingStatusBadge } from "@/presentation/helper/StatusBadge";
import {
  dateToIsoDate,
  formatLocalizedDate,
} from "@/presentation/helper/DateFormatter";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "JobpostingList",
  components: {
    Field,
    Form,
    ErrorMessage,
    VueEditor,
    JobPostingDropdown,
  },
  setup() {
    const store = useStore();
    const interviewerController =
      store.state.ControllersModule.interviewerController;
      const router = useRouter();
    const today = dateToIsoDate(new Date());
    const startDate = ref(today);
    const endDate = ref("");

    const { t } = useI18n();
    const isLoading = ref<boolean>(false);
    const positionDesc = ref("");
    const featureInput = ref("");
    const features = ref<string[]>([]);
    const url = ref("");
    const changeDateRef = ref<null | HTMLElement>(null);

    const swalNotification = new SwalNotification();
    const jobPostingList = ref<JobPostingListModel[]>([]);
    const originalJobPostingList = ref<JobPostingListModel[]>([]);
    const isChanged = ref<boolean>(false);
    const newEndDate = ref("");

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const getJobPostingList = async (page) => {
      isChanged.value = false;
      jobPostingList.value = [];
      originalJobPostingList.value = [];

      isLoading.value = false;
      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const jobPostingListModel: JobPostingListModel = {
        jobPosting: {
          interviews: [],
        },
        page: page,
        pageSize: pageSize.value,
      };

      isLoading.value = true;

      interviewerController
        .jobPostingList(jobPostingListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((jobPosting) => {
              totalItems.value = jobPosting.pagination.total;
              currentPage.value = jobPosting.pagination.page;
              pageCount.value = jobPosting.pagination.pageCount;

              jobPostingList.value.push(jobPosting);
              originalJobPostingList.value.push(jobPosting);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const maxVisible = 2; // Maksimum görünür öğe sayısı
    const showAll = ref(false); // Tüm öğeleri gösterme durumu

    const visibleItems = (interviews: JobPostingInterviewModel[]) => {
      return interviews.slice(0, maxVisible);
    };
    const hiddenItemCount = (interviews: JobPostingInterviewModel[]) => {
      return Math.max(0, interviews.length - maxVisible); // Negatif sayıyı önlemek için
    };

    const hiddenItems = (interviews: JobPostingInterviewModel[]) => {
      return interviews.slice(maxVisible);
    };

    const showAllInterviews = () => {
      showAll.value = true; // Tüm öğeleri göstermek için durumu değiştir
    };

    const form = Yup.object().shape({
      date: Yup.string().required(t("validators_error.required")).label("Date"),
    });

    const copyInterview = () => {
      Swal.fire({
        title: "Bu Mülakatı Kopyalamak İstiyor Musunuz?",
        text: "Bu mülakatın aynısını kopyalamak ve yeni bir tane oluşturmak istiyor musunuz?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: t("swalMessages.SUCCESSFUL"),
            text: "Mülakat başarıyla kopyalandı!",
            icon: "success",
            heightAuto: false,
          }).then(() => {
            window.location.reload();
          });
        }
      });
    };

    // Filtreleme için seçilen durum
    const status = ref("ALL");

    // Filtrelenmiş ilanları döndüren computed özelliği
    const filteredJobPostings = computed(() => {
      if (status.value === "ALL") {
        return jobPostingList.value;
      }
      return jobPostingList.value.filter(
        (job) => job.jobPosting.status === status.value
      );
    });

    // Duruma göre buton ve badge renklerini yönet
    const filterByStatus = (newStatus: string) => {
      status.value = newStatus;
    };

    const addFeature = () => {
      if (featureInput.value.trim()) {
        features.value.push(featureInput.value.trim());
        featureInput.value = ""; // Input alanını temizler
      }
    };

    // Yeni soru nesnesi
    const newQuestion = ref({
      text: "",
      type: "open", // Varsayılan olarak açık uçlu
      options: [] as string[],
    });

    // Eklenen sorular listesi
    const questions: any = ref([]);

    // Yeni opsiyon metni
    const newOptionText = ref("");

    // Soru ekleme fonksiyonu
    const addQuestion = () => {
      if (newQuestion.value.text) {
        questions.value.push({
          ...newQuestion.value,
        });
        newQuestion.value = { text: "", type: "open", options: [] };
      }
    };

    // Opsiyon ekleme fonksiyonu
    const addOption = () => {
      if (newOptionText.value.trim() !== "") {
        newQuestion.value.options.push(newOptionText.value.trim());
        newOptionText.value = "";
      }
    };

    // Opsiyon silme fonksiyonu
    const removeOption = (index: number) => {
      newQuestion.value.options.splice(index, 1);
    };

    const removeQuestion = (index: number) => {
      questions.value.splice(index, 1);
    };

    const interviews = ref([
      {
        id: 1,
        title: "Teknik Mülakat",
        description: "Bu mülakat adayın teknik bilgilerini ölçer.",
      },
      {
        id: 2,
        title: "Yabancı Dil Mülakatı",
        description: "Bu mülakat adayın yabancı dil seviyesini ölçer.",
      },
      {
        id: 3,
        title: "Yazılım Test Mülakatı",
        description: "Bu mülakat adayın test becerilerini ölçer.",
      },
      {
        id: 4,
        title: "Star Mülakat",
        description:
          "Bu mülakat adayın deneyimlerini ve kariyerine olan bakış açısını ölçer.",
      },
    ]);

    const draggedIndex = ref<number | null>(null);
    const dragOverIndex = ref<number | null>(null);

    const onDragStart = (index: number) => {
      draggedIndex.value = index;
    };

    const onDragOver = (index: number) => {
      dragOverIndex.value = index;
    };

    const onDragLeave = () => {
      dragOverIndex.value = null;
    };

    const onDrop = (index: number) => {
      if (draggedIndex.value !== null && draggedIndex.value !== index) {
        const draggedItem = interviews.value[draggedIndex.value];
        interviews.value.splice(draggedIndex.value, 1);
        interviews.value.splice(index, 0, draggedItem);
      }
      cleanDragState();
    };

    const onDragEnd = () => {
      cleanDragState();
    };

    const cleanDragState = () => {
      draggedIndex.value = null;
      dragOverIndex.value = null;
    };

    // Scroll kaymasını engellemek için
    const preventScroll = (event: DragEvent) => {
      event.preventDefault();
    };

    const visibleRequirements = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications) return jobPostingQualifications.slice(0, 3);
    };

    const hiddenRequirementCount = (jobPostingQualifications?: string[]) => {
      if (jobPostingQualifications?.length) {
        const total = jobPostingQualifications.length;
        return total > 3 ? total - 3 : 0;
      }
    };

    const goInterviewDetailPage = (interviewId?: number) => {
      if (interviewId) {
        router.push({ path: `/interviewer/interview-detail/` + interviewId });
      }
    };

    watch(
      () => store.getters.changedAnyJobPosting,
      (newValue) => {
        if (newValue !== null && newValue != 0 && newValue !== undefined) {
          getJobPostingList(1);
        }
      },
      { immediate: true }
    );

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getJobPostingList(1);
    });

    return {
      formatLocalizedDate,
      JobPostingStatus,
      url,
      jobPostingList,
      originalJobPostingList,
      form,
      newEndDate,
      changeDateRef,
      isLoading,
      featureInput,
      features,
      addFeature,
      newQuestion,
      questions,
      newOptionText,
      addQuestion,
      addOption,
      removeOption,
      removeQuestion,
      positionDesc,
      copyInterview,
      visibleItems,
      hiddenItemCount,
      status,
      filteredJobPostings,
      filterByStatus,
      JobPostingStatusBadge,
      showAllInterviews,
      showAll,
      hiddenItems,
      today,
      startDate,
      endDate,
      interviews,
      onDragStart,
      onDragOver,
      onDragLeave,
      onDrop,
      onDragEnd,
      preventScroll,
      draggedIndex,
      dragOverIndex,
      visibleRequirements,
      hiddenRequirementCount,
      currentPage,
      pageCount,
      pageSize,
      totalItems,
      getJobPostingList,
      goInterviewDetailPage
    };
  },
});
</script>

<style>
.hover-effect {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.hover-effect:hover {
  background-color: #f8f9fa; /* Hafif gri renk */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Hafif gölge */
}

.interview-item {
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.interview-item:hover {
  background-color: #f0f0f0; /* Gri arka plan */
  color: #333; /* Metin rengi */
}

.interview-item.disabled:hover {
  background-color: #e0e0e0; /* Hoverda aynı gri kalır */
  color: #6c757d; /* Metin rengi de aynı kalır */
}
/* Grid Layout */
.d-grid {
  display: grid;
  gap: 16px;
}

.grid-columns-4 {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.interview-card {
  position: relative;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  cursor: grab;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 180px;
  box-sizing: border-box;
  transition: none; /* Geçiş animasyonunu iptal et */
}

/* Sürükleme Sırasında Stil */
.dragging {
  opacity: 0.5;
}

.drag-over {
  outline: 2px dashed #007bff; /* Border yerine outline kullanıldı */
  background-color: #e2e6ea;
}

/* Kart içerisindeki içerik */
.interview-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

/* Sıra İbaresi */
.interview-order {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}

.description-text {
  max-height: 200px; /* Yüksekliği 200 piksel olarak sınırlıyoruz */
  overflow-y: auto; /* Taşan içerik için dikey kaydırma çubuğu ekler */
  word-wrap: break-word; /* Uzun kelimeleri satır sonunda kırar */
}

.d-flex.align-items-center.py-2 {
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
</style>
